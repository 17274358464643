"use client";
import React from "react";
import Image from "next/image";
import home_banner from "/public/banners/home_banner.jpg";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function Header(props) {
  const pathname = usePathname();
  const banner = props.banner || home_banner;
  const altText = props.altText || "FactoryCat | Industrial Floor Clean Banner";
  const title =
    props.title || "Industrial Sweepers: Your Solution for Spotless Floors";
  let isApplication;
  if (pathname.includes("applications")) {
    isApplication = true;
  } else {
    isApplication = false;
  }
  return (
    <div className="header">
      <Image src={banner} width={4000} height={4000} alt={altText} />
      <div className="text-wrapper">
        <div className="background-wrapper">
          <h2 className="header-title">
            {title ? (
              title
            ) : (
              <>
                <span>Industrial</span> Sweepers: Your Solution for Spotless
                Floors
              </>
            )}
          </h2>
          <h2>Find the perfect solution for maintaining spotless floors </h2>
          {isApplication ? (
            <div className="link-wrapper">
              <Link href="/applications">More Applications</Link>
              <Link href="/contact">Get in Touch</Link>
            </div>
          ) : (
            <div className="link-wrapper">
              <Link href="/">Explore Our Line Up</Link>
              <Link href="/contact">Get in Touch</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
